import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const HeaderMysql = () => {
  return (
    <>
      <section className="cloud-server-header web-hosting-gradient">
        <div className="container-xl">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="page-title text-white">MySQL Cloud Database</h1>
              <p className="text-white pb-3 description">
                Get your MySQL Database for your application.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-lg-7 mt-2 mt-lg-0">
              <ul className="text-start text-white list-unstyled mb-0">
                <li className="d-flex">
                  <div className="ms-4">
                    <h2 className="title mb-2">Powerful Control Panel</h2>
                    <p className="text-white pb-3 description">
                      World's Leading Web Hosting Control Panel for SQL hosting
                      server. Create &amp; Manage MySQL Database.
                    </p>
                  </div>
                </li>
                <li className="d-flex">
                  <div className="ms-4">
                    <h2 className="title mb-2">Instant Setup</h2>
                    <p className="text-white pb-3 description">
                      As soon as you make a successful payment, your web hosting
                      and domain names will be activated immediately. No waiting
                      time whatsoever.
                    </p>
                  </div>
                </li>
                <li className="d-flex">
                  <div className="ms-4">
                    <h2 className="title mb-2">PHPMyAdmin for MySQL Server</h2>
                    <p className="text-white pb-0 description">
                      PhpMyAdmin for MySQL Server is a web-based application, it
                      includes a powerful editable datagrid that allows editing
                      and deleting records while browsing your data.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="d-none d-lg-block col-12 col-lg-5">
              <div className="text-center ms-0 ms-xl-5">
                <StaticImage
                  className="img-fluid header-banner"
                  alt="Shared MSSQL Database hosting"
                  src="../../../img/svg/mssql-database-server.svg"
                  width={256}
                  height={256}
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HeaderMysql
