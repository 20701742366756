import React from "react"
import CurrencyContext from "../../../context/currency-context"
import PricingSelect from "../../ui/pricingSelect"
import Pricing from "../../ui/pricing"
import { useStaticQuery, graphql } from "gatsby"
const PlansMySQLDatabase = () => {
  const allData = useStaticQuery(graphql`
    query {
      allProductsJson(filter: { gid: { eq: 12 } }) {
        edges {
          node {
            pid
            name
            description
            pricing {
              INR {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              MYR {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              SGD {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              USD {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              CAD {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
            }
          }
        }
      }
    }
  `)
  const data = allData.allProductsJson.edges
  if (data.length <= 0) {
    return <></>
  }
  return (
    <CurrencyContext.Consumer>
      {(context) => {
        return (
          <div className="row">
            {data.map((item, index) => (
              <div key={index} className="col-12 col-lg-3">
                <div className="card card-shadow text-start position-relative py-5 px-4 mb-3">
                  <h3 className="card-title">{item.node.name}</h3>
                  <Pricing pricing={item.node.pricing} id={item.node.pid} />

                  <PricingSelect
                    pricing={item.node.pricing}
                    id={item.node.pid}
                  />
                  <ul
                    className="mb-2"
                    dangerouslySetInnerHTML={{ __html: item.node.description }}
                  ></ul>
                </div>
              </div>
            ))}
          </div>
        )
      }}
    </CurrencyContext.Consumer>
  )
}

export default PlansMySQLDatabase
